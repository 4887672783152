import { EventEmitter, Injectable, OnInit, inject } from '@angular/core';
import { 
  MediaMatcher, 
  BreakpointObserver, 
  BreakpointState,
  Breakpoints 
} from '@angular/cdk/layout';


@Injectable({
  providedIn: 'root'
})
export class BreakpointService implements OnInit {

  public breakpointObserver = inject(BreakpointObserver);
  public mediaMatcher = inject(MediaMatcher);

  matcher: MediaQueryList;
  isDesktop: boolean;
  isMobile: boolean;
  isTablet: boolean;
  device: string = '';
  deviceChange: EventEmitter<string> = new EventEmitter<string>();

  ngOnInit(): void {
    this.whatDevice();  
  }

  // Metodo Breakpoints observer
  //*****************************/
  initBreakpointObserver() {
    // *** BreakPoints ***
    // Breakpoints.XSmall: max-width = 599.99px
    // Breakpoints.Small: min-width = 600px and max-width = 959.99px
    // Breakpoints.Medium: min-width = 960px and max-width = 1279.99px
    // Breakpoints.Large: min-width = 1280px and max-width = 1919.99px
    // Breakpoints.XLarge: min-width = 1920px
    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium,
      Breakpoints.Large,
      Breakpoints.XLarge
    ]).subscribe(result => {
      if (result.breakpoints[Breakpoints.XSmall]) {
        this.isMobile = true;
        this.isDesktop = false;
        this.isTablet = false;
        this.whatDevice();
        this.deviceChange.emit(this.device);
      }
      if (result.breakpoints[Breakpoints.Small]) {
        this.isTablet = true;
        this.isMobile = false;
        this.isDesktop = false; 
        this.whatDevice();
        this.deviceChange.emit(this.device);
      }
      if (result.breakpoints[Breakpoints.Medium]) {
        this.isDesktop = true;
        this.isTablet = false; 
        this.isMobile = false;
        this.whatDevice();
        this.deviceChange.emit(this.device);
      }
      if (result.breakpoints[Breakpoints.Large]) {
        this.isDesktop = true;
        this.isTablet = false;
        this.isMobile = false;
        this.whatDevice();
        this.deviceChange.emit(this.device);
      }
      if (result.breakpoints[Breakpoints.XLarge]) {
        this.isDesktop = true;
        this.isTablet = false;
        this.isMobile = false;
        this.whatDevice();
        this.deviceChange.emit(this.device);
      }
    });
  }

  whatDevice(): any{
    if(this.isMobile) return this.device = 'mobile';
    if(this.isTablet) return this.device = 'tablet';
    if(this.isDesktop) return this.device = 'desktop';
  }

  // Metodo MediaMatcher
  //*****************************/
  initMediaMatcher() {
    this.matcher = this.mediaMatcher.matchMedia('(min-width: 500px)');
    this.matcher.addListener(this.myListener);
  }

  destroyMediaMatcher() {
    this.matcher.removeListener(this.myListener);
  }

  myListener(event) {
    
  }

}
